import React, { Fragment, useEffect, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from "gatsby";
import useMediaQuery from '@material-ui/core/useMediaQuery';

//carga componentes
import MenuPages from '../components/menuPages';
import Seo from "../components/seo";

//estilos
import Clases from "../styles/clases";

const IndexPage = () => {

  const classes = Clases();
  const esDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const esTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));

  //states

  const [anchorElMenuPrincipal, setAnchorElMenuPrincipal] = useState(null);
  const [tiempo, SetTiempo] = useState(false);

  //useEffect

  useEffect(() => {
    const timer = setTimeout(() => {
      SetTiempo(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  //funciones

  const handleClickMenuPrincipal = (e) => {
    setAnchorElMenuPrincipal(anchorElMenuPrincipal ? null : e.currentTarget);
  };

  const handleCloseMenuPrincipal = () => {
    setAnchorElMenuPrincipal(null);
  };
  return (
    <Fragment>
      {tiempo ? (
        <Fragment>
          <Seo title="Home" />
          <div className={classes.content}>
            <Fab
              className={classes.posFixed}
              style={{ zIndex: 4 }}
              color="primary"
              onClick={handleClickMenuPrincipal}
            >
              <MenuIcon />
            </Fab>
            <Popover
              id="customized-menu2"
              open={Boolean(anchorElMenuPrincipal)}
              anchorEl={anchorElMenuPrincipal}
              onClose={handleCloseMenuPrincipal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              disableScrollLock={true}
              classes={{ paper: classes.popoverPaper }}
            >
              <MenuPages />
            </Popover>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={12} md={10} lg={9}>
                <Box
                  style={{ padding: 16, marginTop: 64 }}
                >
                  <Typography variant={esDesktop || esTablet ? "h1" : "h2"} className={classes.blanc} style={esDesktop || esTablet ? { fontWeight: 300 } : { fontWeight: 300, wordWrap: 'break-word' }}>
                    Hello, We're elevenkosmos
                  </Typography>
                  <br />
                  <Typography variant="h4" className={classes.blanc} style={{ fontWeight: 300 }}>Opening Up Digital Fiction, Art & Web Experiences for the next generation of readers.</Typography>
                </Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ padding: 16, marginTop: 32 }}
                  spacing={2}
                >
                  <Grid item xs={12} md={6} lg={6}>
                    <Link to='https://www.anomaliagravitatoria.net' target="_blank" rel="noopener noreferrer">
                      <Box className={classes.box1}>
                        <Typography variant="h3" className={classes.blanc} style={{ fontWeight: 300, marginBottom: "10px" }}>Anomalía gravitatoria</Typography>
                        <Typography variant="body1" className={classes.blanc} style={{ fontWeight: 300 }}>
                          "Anomalía Gravitatoria" redefine el ejercicio literario digital al deconstruir y analizar poesía de poetas de todas las eras. Este singular viaje literario digital trasciende las barreras del tiempo, desafiando las convenciones poéticas a través de la inmersión en el código. Cada ejercicio se convierte en una herramienta literaria, desentrañando las capas simbólicas de versos clásicos y contemporáneos. El proyecto invita al lector a explorar las anomalías gravitatorias en el tejido de la poesía, revelando conexiones inesperadas y profundidades ocultas en cada palabra. Programado en React y desplegado en un CMS-PHP.</Typography>
                        <br />
                        <Typography variant="h5" className={classes.blanc} style={{ fontWeight: 300 }}>DIGITAL LITERATURE</Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Link to='https://www.artikaweb.com/logos/' target="_blank" rel="noopener noreferrer">
                      <Box className={classes.box1}>
                        <Typography variant="h3" className={classes.blanc} style={{ fontWeight: 300, marginBottom: "10px" }}>Logos</Typography>
                        <Typography variant="body1" className={classes.blanc} style={{ fontWeight: 300 }}>
                          En "Logos: Un Viaje Digital a través de las Palabras", una obra de literatura digital programada en JavaScript explora el griego clásico para desentrañar sus significados. Utilizando el código como herramienta lingüística, el proyecto sumerge al lector en el contexto cultural y filosófico de la antigua Grecia. La interacción revela capas de significado en cada término, destacando las conexiones intrincadas entre la lengua y la sabiduría griega. "Logos" proporciona un análisis literario profundo, invitando al lector a explorar cómo las palabras dan forma al pensamiento y la comprensión en esta experiencia digital única.</Typography>
                        <br />
                        <Typography variant="h5" className={classes.blanc} style={{ fontWeight: 300 }}>DIGITAL LITERATURE</Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Link to='https://www.artikaweb.com/cabalapop/' target="_blank" rel="noopener noreferrer">
                      <Box className={classes.box1}>
                        <Typography variant="h3" className={classes.blanc} style={{ fontWeight: 300, marginBottom: "10px" }}>Cábala Pop</Typography>
                        <Typography variant="body1" className={classes.blanc} style={{ fontWeight: 300 }}>
                          "Cábala Pop" es una obra de literatura digital que fusiona la mística cábala con la tecnología moderna. Programada en JavaScript, crea un intrincado laberinto virtual donde cada línea de código se convierte en un sendero hacia la comprensión de los secretos del alma. Los nodos de significado codificados en la estructura de la programación se encuentran mientras el lector explora las ramificaciones de la cábala. La interacción revela antiguos misterios a través de algoritmos contemporáneos, transformando las palabras en símbolos que, al ser descifrados, desvelan capas de sabiduría ancestral en el tejido digital.</Typography>
                        <br />
                        <Typography variant="h5" className={classes.blanc} style={{ fontWeight: 300 }}>DIGITAL LITERATURE</Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Link to='https://www.artikaweb.com/5000palabras/index/index.html' target="_blank" rel="noopener noreferrer">
                      <Box className={classes.box1}>
                        <Typography variant="h3" className={classes.blanc} style={{ fontWeight: 300, marginBottom: "10px" }}>5000 palabras</Typography>
                        <Typography variant="body1" className={classes.blanc} style={{ fontWeight: 300 }}>"5000 Palabras" es una experiencia literaria hipermedia única, creada en JavaScript. Fusiona diversas formas literarias en un entramado narrativo complejo, que va desde relatos cautivadores y micro-relatos hasta poesía generativa y colaborativa. Juegos de palabras desafían al lector, mientras elementos multimedia amplían la experiencia literaria. A través de paseos por espacios virtuales, el lector explora mundos interactivos mediante la magia de la programación. "5000 Palabras" redefine la narrativa digital al fusionar creatividad literaria y tecnología, ofreciendo una inmersión única en la literatura hipermedia.</Typography>
                        <br />
                        <Typography variant="h5" className={classes.blanc} style={{ fontWeight: 300 }}>DIGITAL LITERATURE</Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Link to='/nfts'>
                      <Box className={classes.box2}>
                        <Typography variant="h3" className={classes.blanc} style={{ fontWeight: 300, marginBottom: "10px" }}>#narcopoemas</Typography>
                        <Typography variant="body1" className={classes.blanc} style={{ fontWeight: 300 }}>These NFTs represent a full decade of work, they show the foundational moments of our motivations. Holders of these NFTs will come to realize their magic utility.</Typography>
                        <br />
                        <Typography variant="h5" className={classes.blanc} style={{ fontWeight: 300 }}>NFTs COLLECTION</Typography>
                      </Box>
                    </Link>
                  </Grid>
                  <Box
                    style={{ padding: 16, marginTop: 48 }}
                  >
                    <Typography variant="h2" className={classes.blanc} style={{ fontWeight: 300 }}>About</Typography>
                    <br />
                    <Typography variant="h4" className={classes.blanc} style={{ fontWeight: 300 }}>We are the pourers of our own creative universe.</Typography>
                    <Typography variant="h6" className={classes.blanc} style={{ fontWeight: 300 }}>We make ourselves outside the limits of common thought. We ride the crest of the metaverse wave, driving the initiatives that shape our stories. Generative, scalable and unique artificial intelligence at the service of Homo Cognos.</Typography>
                  </Box>
                  <Box
                    style={{ padding: 16, marginTop: 48 }}
                  >
                    <Typography variant="h2" className={classes.blanc} style={{ fontWeight: 300 }}>Get in touch</Typography>
                    <br />
                    <Typography variant="h6" className={classes.blanc} style={{ fontWeight: 300 }}>Say <a href="mailto:info@elevenkosmos.net">Hi</a> or find me on other platforms: <a href="https://twitter.com/11kosmos" target="_blank">Twitter</a>.</Typography>
                  </Box>
                </Grid>
                <Box
                  p={2}
                  mt={7}
                  style={{ textAlign: 'center' }}
                >
                  <Typography variant="body2" className={classes.blanc} style={{ fontWeight: 300 }}>Copyleft U+1F12F {new Date().getFullYear()}. Creation license released.</Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fragment >
      ) : null}
    </Fragment >
  )
}

export default IndexPage
